import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {FormFooter} from '../../common/FormFooter';
import {useAcknowledgementBatch} from '../../../hooks/services/useAcknowledgementBatch';
import {Checkbox, Spinner} from '@fluentui/react';
import styles from './MyAcknowledgement.module.scss';
import {
  BooleanColumn,
  EnhancedDetailsList,
  OtherColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {BatchAcknowledgementNotCompleted} from '../../../models/BatchAcknowledgementNotCompleted';
import {
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {LinksListColumn} from '../../common/EnhancedDetailsList/ColumnTypes/LinksListColumn';
import {SendAcknowledgementEmail} from '../Process/components/SendAcknowledgementEmail';
import {TheButton} from '../../common/TheButton';
import { UserDto } from '../../../services/generated';

interface IUsersListProps {
  acknowledgementId: number;
  isModalOpen: boolean;
  selectedUsers: UserDto[];
  selected: boolean,
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserDto[]>>
  setSelected: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal: () => void;
}

export const UsersList: FC<IUsersListProps> = ({acknowledgementId, selectedUsers, setSelectedUsers,setSelected, selected, isModalOpen, onCloseModal}) => {
  const {getdUserWithDataToAcknowledge} = useAcknowledgementBatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elements, setElements] = useState<BatchAcknowledgementNotCompleted[]>([]);
  const onRowCheck = useCallback(
    (element: BatchAcknowledgementNotCompleted, isChecked: boolean) => {
      if (isChecked) {
        const users = [...new Set([...selectedUsers, element.user])];
        setSelectedUsers(users);
      } else {
        const users = selectedUsers.filter(user => user.id !== element.user.id);
        setSelectedUsers(users);
      }
    },
    [selectedUsers, setSelectedUsers]
  );


  const disabled = useMemo(() => selectedUsers.length === 0, [selectedUsers]);

  useEffect(() => {
    setIsLoading(true);
    setSelected(false);
    setSelectedUsers([]);
    if (acknowledgementId === 0) {
      setIsLoading(false);
      return;
    }
    getdUserWithDataToAcknowledge(acknowledgementId).then(response => {
      if (response.result) {
        const result = response.result;
        result.sort((a, b) => {
          if (!a.user.userPrincipalName) return 1;
          if (!b.user.userPrincipalName) return -1;
          return a.user.userPrincipalName > b.user.userPrincipalName ? 1 : -1;
        });
        setElements(result);
      }
      setIsLoading(false);
    });
  }, [getdUserWithDataToAcknowledge, acknowledgementId, setSelectedUsers, setSelected]);


  const toggleSelect = useCallback(
    () => {
      const checkedValue = !selected;
      const modifiedElements: BatchAcknowledgementNotCompleted[] = elements.map(item => ({
        ...item,
        isChecked: checkedValue
      })) as any as BatchAcknowledgementNotCompleted[];
      setSelected(checkedValue);
      setElements(modifiedElements);
      if (checkedValue === false)
        {
          setSelectedUsers([]);
        } 
      else 
      {
        setSelectedUsers(modifiedElements.map(item => item.user));
      }
    },
    [selected,elements, setSelected, setSelectedUsers]
  );

  const columnsView = useMemo(() => {
    const columns: IEnhancedColumn<BatchAcknowledgementNotCompleted>[] = [
      {
        key: SpecialColumnKey.Select,
        name: '',
        data: OtherColumn,
        minWidth: 30,
        onRender(item, index, column) {
          return (
            <Checkbox
              className={styles.select}
              onChange={(ev, checked) => onRowCheck(item, !!checked)}
              checked={selectedUsers.some(user => user.id === item.user.id)}
            />
          );
        }
      },
      {
        key: 'user',
        name: 'User',
        data: UserDtoColumn,
        minWidth: 320,
        
        
      },
      {
        key: 'documents',
        name: 'Not Confirmed Documents',
        minWidth: 750,
        data: LinksListColumn
      },
      {
        key: 'readOnly',
        name: 'Finished',
        data: BooleanColumn,
        minWidth: 150
      }
    ];
    return columns;
  }, [onRowCheck, selectedUsers]);

  return (
    <>
      <ModalWithHeader
        header={'Users, who have not acknowledged'}
        isVisible={isModalOpen}
        dismiss={onCloseModal}>
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={styles.buttonsContainer}>
              <TheButton
                className={styles.buttonPaddingRight}
                primary
                onClick={() => {
                  toggleSelect();
                }}>
                {selected ? 'Unselect All' : 'Select All'}
              </TheButton>
              <SendAcknowledgementEmail
                disabled={disabled}
                users={selectedUsers}
                acknowledgementId={acknowledgementId}
              />
            </div>
            <EnhancedDetailsList< 
              BatchAcknowledgementNotCompleted,
              BatchAcknowledgementNotCompleted,
              BatchAcknowledgementNotCompleted
            >
              
              hideSearch={true}
              columns={columnsView}
              items={elements}
              />
          </>
        )}
        <>
          <FormFooter onCancel={onCloseModal} />
        </>
      </ModalWithHeader>
    </>
  );
};
