import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';

import {actions, PermissionToCheck, ProcessType} from '../../../../enums';
import {checkPermissions} from '../../../../helpers/permissionsHtml';
import {useService} from '../../../../hooks';
import {useChunkedRequest} from '../../../../hooks/services/useChunkedRequest';
import {ParentProcess, ProcessFlatStructure, ProcessSlim, Unit} from '../../../../models';
import {ITreeColumn, ITreeColumnBulk} from '../../../common/TreeBuilder/TreeColumn';
import {ProcessRowOwner, ProcessRowType, UnitDropdown, UnitDropdownBulk} from './inputs';
import {TreeProcessModel} from './TreeProcessModel';

import {AuthenticationContext} from '../../../../providers/AuthenticationContext';
import {MainStoreContext} from '../../../../providers/MainStoreContext';
import {useUnit} from '../../../../hooks/services/useUnit';
interface publishOptions {
  sendNotification: boolean;
}
export const useUnitManagementProcess = () => {
  const navigate = useNavigate();
  const {unitId: unitIdStr} = useParams();
  const unitId = Number(unitIdStr);
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {getUnit} = useUnit();
  const {getUnitProcessOwner} = useService();
  const {users} = useContext(MainStoreContext);

  const [defaultUnitProcessOwner, setDefaultUnitProcessOwner] = useState<string | undefined>();

  const getDefaultProcessOwner = async () => {
    const processOwnerData = await getUnitProcessOwner(unitId);
    if (processOwnerData?.result) {
      setDefaultUnitProcessOwner(processOwnerData.result.azureId);
    } else {
      setDefaultUnitProcessOwner(undefined);
    }
  };

  const {
    getProcessesToManage,
    getProcessesPublishedForUnitFlatForManage,
    updateProcessToManage,
    getUserPermissionsPage,
    getUserById
  } = useService();

  const [isLoading, setIsLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [canSelectUnit, setCanSelectUnit] = useState(false);
  const [revealedMasterProcessIds, setRevealedMasterProcessIds] = useState<Array<number>>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unitTitle, setUnitTitle] = useState<string>();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [modifiedProcesses, setModifiedProcesses] = useState<TreeProcessModel[]>([]);
  const [sendNotification, setSendNotification] = useState<boolean>(true);

  const maxChunkSize = 1;

  const {sendRequests, isRequestInProgress, moreThanOneChunk, progressStatus} = useChunkedRequest<
    TreeProcessModel,
    void,
    publishOptions
  >(async (data, options) => {
    const mapBeforeSave = data.map(item => item.rowValue);
    await updateProcessToManage(unitId, mapBeforeSave, options ? options.sendNotification : true);
  }, maxChunkSize);

  const isAnythingLoading = useMemo(
    () => isLoading || isRequestInProgress,
    [isLoading, isRequestInProgress]
  );

  const [itemsFlat, setItemsFlat] = useState<TreeProcessModel[]>([]);

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      const response = await getUnit(Number(unitId));
      if (response && response.result) {
        setUnitTitle(prepareUnitTitle(response.result));
      }
    })();
  }, [getUnit, unitId]);

  useEffect(() => {
    setIsModalVisible(moreThanOneChunk);
  }, [moreThanOneChunk]);

  const onDismiss = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getUserPermissionsPage(unitId);
      if (response?.result) {
        const canSelect = checkPermissions(
          PermissionToCheck.UnitFormProcessessUnitSelector,
          actions.UPDATE,
          response.result
        );
        setCanSelectUnit(canSelect);
      }
    })();
  }, [getUserPermissionsPage, setCanSelectUnit, unitId]);

  const refreshData = useCallback(async () => {
    const processesToManageResult = await getProcessesToManage(unitId);
    const processesPublishedForUnitResult = await getProcessesPublishedForUnitFlatForManage(unitId);
    const processesPublishedForUnit = processesPublishedForUnitResult.result;
    if (!processesToManageResult.result || !processesPublishedForUnit) return;
    processesPublishedForUnit.forEach(processPublished => {
      const parent = processesPublishedForUnit.find(
        item => item.masterProcessId === processPublished.parentMasterProcessId
      );
      if (parent) {
        processPublished.setParent(parent);
      }
    });
    const itemsFlat = processesToManageResult.result
      .map(
        process =>
          new TreeProcessModel(
            process,
            processesPublishedForUnit?.find(
              item => item.masterProcessId === process.masterProcess.id
            )
          )
      )
      .filter(item => item.isVisible);
    setItemsFlat(itemsFlat);
    const preselectedMasterProcessStr = new URLSearchParams(window.location.search).get('reveal');
    if (preselectedMasterProcessStr) {
      const revealedMasterProcessIds: Array<number> = [];
      let nextParentMasterProcessId = Number(preselectedMasterProcessStr);
      while (nextParentMasterProcessId) {
        const next = nextParentMasterProcessId;
        const nextProcess = processesToManageResult.result.find(
          process => process.masterProcess.id === next
        );
        if (nextProcess?.masterProcess.parentId) {
          revealedMasterProcessIds.push(nextProcess.masterProcess.parentId);
          nextParentMasterProcessId = nextProcess.masterProcess.parentId;
        } else {
          break;
        }
      }
      setRevealedMasterProcessIds(revealedMasterProcessIds);
    }
  }, [getProcessesToManage, getProcessesPublishedForUnitFlatForManage, unitId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refreshData();
      setIsLoading(false);
    })();
  }, [refreshData]);

  const sendRequestAndRefreshData = useCallback(
    async (modifiedProcesses: TreeProcessModel[], sendNotification: boolean) => {
      await sendRequests(modifiedProcesses, {sendNotification});
      await refreshData();
    },
    [sendRequests, refreshData]
  );

  const onCancel = useCallback(() => {
    navigate(`/unit/${unitId}/manage`);
  }, [navigate, unitId]);

  const onSubmit = useCallback(
    async (sendNotification: boolean = true) => {
      const modifiedProcesses = itemsFlat
        .filter(item => item.isModified)
        .sort((a, b) => a.processToManage.masterProcess.id - b.processToManage.masterProcess.id);
      const isValid = modifiedProcesses.every(item => item.isValid);
      setIsInvalid(!isValid);
      setSendNotification(sendNotification);

      if (isValid) {
        const processToDelete = modifiedProcesses.filter(item => !item.isChecked);
        if (processToDelete.length > 0) {
          setModifiedProcesses(modifiedProcesses);
          setIsConfirmModalVisible(true);
        } else {
          await sendRequestAndRefreshData(modifiedProcesses, sendNotification);
        }
      }
    },
    [itemsFlat, sendRequestAndRefreshData]
  );

  const onCloseConfirmModal = useCallback(() => {
    setIsConfirmModalVisible(false);
  }, []);

  const onConfirmConfirmModal = useCallback(async () => {
    setIsConfirmModalVisible(false);
    await sendRequestAndRefreshData(modifiedProcesses, sendNotification);
  }, [sendRequestAndRefreshData, modifiedProcesses, sendNotification]);

  const selectAllProcessesAndSave = useCallback(
    async (sendNotification: boolean = true) => {
      const allCheckedData = itemsFlat.map(item => {
        item.isChecked = true;
        item.processOwnerAzureId = item.processOwnerAzureId || defaultUnitProcessOwner;
        return item;
      });
      setItemsFlat(allCheckedData);

      onSubmit(sendNotification);
    },
    [defaultUnitProcessOwner, itemsFlat, onSubmit]
  );

  const parentUnitsList = useRef<{[masterProcessId: number]: ParentProcess[]}>({});

  const onUnitsDownloaded = useCallback((list: {[masterProcessId: number]: ParentProcess[]}) => {
    parentUnitsList.current = list;
  }, []);

  const onCustomBulkChange = useCallback((item: TreeProcessModel, unitId: number) => {
    const parentProcess = parentUnitsList.current[item.processToManage.masterProcess.id]?.find(
      process => process.unitId === unitId
    );
    if (parentProcess && !parentProcess.canNotBeImplemented) {
      item.parentUnitId = parentProcess.unitId;
      item.parentUnitProcessId = parentProcess.id;
      if (item.processPublished) {
        item.processPublished.parentUnitTitle = parentProcess.unitTitle;
      } else {
        item.processPublished = {parentUnitTitle: parentProcess.unitTitle} as ProcessFlatStructure;
      }
    }
  }, []);

  const columns = useMemo(() => {
    const cols: ITreeColumn<TreeProcessModel>[] = [
      {
        key: 1,
        title: 'Title',
        type: 'title',
        showCheckbox: true,
        getTitle: (model: TreeProcessModel) => model.title,
        minCSSWidth: '500px',
        property: 'title',
        exportToExcel: true
      },
      {
        key: 2,
        title: 'Parent Unit',
        tooltip: `Some units may be disabled and marked with an asterisk - impossible to be selected due to potential circular references`,
        type: canSelectUnit ? 'bulk' : 'simple',
        onRenderColumn: (model: TreeProcessModel) => (
          <UnitDropdown
            masterProcessId={model.id}
            defaultSelectedUnitId={model.parentUnitId || undefined}
            defaultSelectedUnitTitle={model.processPublished?.parentUnitTitle || undefined}
            disabled={!canSelectUnit || !model.isChecked || model.isMirrorCopy}
            onChange={(ev, option) => {
              const parentUnitId = option?.key as number;
              const parentProcessId = option?.data;
              model.parentUnitId = parentUnitId;
              model.parentUnitTitle = option?.text;
              model.parentUnitProcessId = parentProcessId;
            }}
          />
        ),
        onRenderBulk: (
          model: TreeProcessModel,
          column: ITreeColumnBulk<TreeProcessModel>,
          idsForEdit: number[],
          disabled?: boolean
        ) => {
          const masterIdsForEdit = idsForEdit
            .map(
              id => itemsFlat.find(item => item.id === id)?.processToManage.masterProcess.id || 0
            )
            .filter(id => !!id);
          return (
            <UnitDropdownBulk
              model={model}
              masterIdsForEdit={masterIdsForEdit}
              disabled={!canSelectUnit || disabled}
              onUnitsDownloaded={onUnitsDownloaded}
            />
          );
        },
        property: 'parentUnitId',
        onCustomBulkChange,
        exportToExcel: true,
        getExportToExcelValue: model => model.parentUnitTitle
      },
      {
        key: 3,
        title: 'Type',
        tooltip: 'Select NonProcess for non-clickable process (link disabled)',
        type: 'bulk',
        onRenderColumn: (
          model: TreeProcessModel,
          column: ITreeColumnBulk<TreeProcessModel>,
          disabled?: boolean
        ) => <ProcessRowType model={model} disabled={disabled || model.isMirrorCopy} />,
        property: 'type',
        exportToExcel: true,
        getExportToExcelValue: model =>
          model.type === ProcessType.NonProcess ? 'NonProcess' : 'Process'
      },
      {
        key: 4,
        title: 'Process Owner',
        type: 'bulk',
        onRenderColumn: (
          model: TreeProcessModel,
          column: ITreeColumnBulk<TreeProcessModel>,
          disabled?: boolean
        ) => <ProcessRowOwner model={model} disabled={disabled || model.isMirrorCopy} />,
        property: 'processOwnerAzureId',
        required: true,
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (model.processOwnerAzureId) {
            let user = await users.cacheById[model.processOwnerAzureId];
            if (!user) {
              user = await getUserById(model.processOwnerAzureId);
            }
            return user?.displayName;
          }
        }
      },
      {
        key: 0,
        title: 'Active',
        type: 'simple',
        property: 'isChecked',
        exportToExcel: true,
        hidden: true,
        onRenderColumn: () => null,
        getExportToExcelValue: item => (item.isChecked ? 'True' : 'False')
      }
    ];
    return cols;
  }, [canSelectUnit, itemsFlat, users, onCustomBulkChange, onUnitsDownloaded, getUserById]);

  const getBulkModel = useCallback(() => new TreeProcessModel(new ProcessSlim({} as any)), []);

  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        await getDefaultProcessOwner();
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading]);
  /* eslint-enable*/

  return useMemo(
    () => ({
      unitId,
      unitTitle,
      isInvalid,
      itemsFlat,
      isAnythingLoading,
      selectAllProcessesAndSave,
      getBulkModel,
      onCloseConfirmModal,
      onConfirmConfirmModal,
      isConfirmModalVisible,
      modifiedProcesses,
      columns,
      revealedMasterProcessIds,
      onCancel,
      onSubmit,
      onDismiss,
      isModalVisible,
      progressStatus
    }),
    [
      unitId,
      unitTitle,
      isInvalid,
      itemsFlat,
      isAnythingLoading,
      selectAllProcessesAndSave,
      getBulkModel,
      isConfirmModalVisible,
      modifiedProcesses,
      onCloseConfirmModal,
      onConfirmConfirmModal,
      columns,
      revealedMasterProcessIds,
      onCancel,
      onSubmit,
      onDismiss,
      isModalVisible,
      progressStatus
    ]
  );
};
