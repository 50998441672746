import {UserDto} from '../services/generated';
import {BatchAcknowledgementNotCompletedDto} from '../services/generated/models/BatchAcknowledgementNotCompletedDto';
import {DocumentSlim} from './DocumentSlim';

export class BatchAcknowledgementNotCompleted implements BatchAcknowledgementNotCompletedDto {
  public id: number;
  public user: UserDto;
  public readOnly: boolean;
  public documents: Array<DocumentSlim> | null;
  public isChecked: boolean;
  constructor(batch: BatchAcknowledgementNotCompletedDto) {
    this.id = batch.id;
    this.user = batch.user;
    this.documents = batch.documents?.map(document => new DocumentSlim(document)) || null;
    this.readOnly = batch.readOnly;
    this.isChecked = false;
  }
}
