import {FC} from 'react';
import {TheButton} from '../../../common/TheButton';
import {UserDto} from '../../../../services/generated';

interface ISendMailProps {
  users?: UserDto[];
  acknowledgementId: number;
  disabled: boolean;
}

export const SendAcknowledgementEmail: FC<ISendMailProps> = ({users, acknowledgementId, disabled}) => {
  const emails = users?.map(x => x.userPrincipalName).join(';');
  const subject = encodeURIComponent('Action needed, IMS Notification: Documents Acknowledgement - Reminder');
  const body = encodeURIComponent(`Dear colleague,

Please follow this link to access the acknowledgement page. (${window.location.origin}/acknowledgement/${acknowledgementId}).
`);
  return (
    <TheButton
      disabled={disabled}
      iconProps={{iconName: 'NewMail'}}
      href={`mailto:?bcc=${emails}&subject=${subject}&body=${body}`}>
      Contact
    </TheButton>
  );
};
