import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Helmet} from 'react-helmet';
import {TheButton} from '../../common/TheButton';
import {
  BooleanColumn,
  DateTimeColumn,
  DropdownColumn,
  EnhancedDetailsList,
  OtherColumn,
  ProcessesColumn,
  RichtextColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {ProcessCommentManage} from '../../../models';
import {Checkbox, Spinner} from '@fluentui/react';
import styles from './FeedbackCenter.module.scss';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {useComments} from '../../../hooks/services/useComments';
import {FeedbackCenterDelete} from './FeedbackCenterDelete';
import {FormFooter} from '../../common/FormFooter';
import {useUnit} from '../../../hooks/services/useUnit';

export const FeedbackCenter: FC<{unitId?: number}> = ({unitId}) => {
  const {getCommentsAdmin, getCommentsUnit, deleteComments} = useComments();
  const {getUnit} = useUnit();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [comments, setComments] = useState<ProcessCommentManage[]>([]);
  const [helmetTitle, setHelmetTitle] = useState<string>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const onRowCheck = useCallback(
    (comment: ProcessCommentManage, isChecked: boolean) => {
      if (isChecked) {
        const ids = [...new Set([...selectedIds, comment.id])];
        setSelectedIds(ids);
      } else {
        const ids = selectedIds.filter(id => id !== comment.id);
        setSelectedIds(ids);
      }
    },
    [selectedIds]
  );

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const onOpenDelete = useCallback(() => setIsDeleteModal(true), []);
  const onCloseDelete = useCallback(() => {
    setIsDeleteModal(false);
  }, []);

  const refresh = useCallback(async () => {
    setIsLoading(true);
    const response = await (unitId ? getCommentsUnit(unitId) : getCommentsAdmin());
    if (response.result) {
      const newComments = response.result;
      newComments.sort((a, b) => (a.created < b.created ? 1 : -1));
      setComments(newComments);
      const ids = selectedIds.filter(
        id => !response.result || response.result.some(comment => comment.id === id)
      );
      setSelectedIds(ids);
    }
    setIsLoading(false);
  }, [getCommentsAdmin, getCommentsUnit, selectedIds, unitId]);

  const onDeleteConfirm = useCallback(async () => {
    setIsLoading(true);
    await deleteComments(selectedIds);
    setSelectedIds([]);
    setIsDeleteModal(false);
    refresh();
  }, [deleteComments, refresh, selectedIds]);

  const prepareHelmetTitle = useCallback(async () => {
    if (unitId) {
      const response = await getUnit(Number(unitId));
      if (response && response.result) {
        const unit = response.result
        const unitTitle = unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
        return `IMS ${unitTitle} Feedback Center` 
      }
    }
    else 
    {
      return "IMS Feedback Center"
    }
  },[unitId,getUnit]);

  useEffect(() => {
    (async () => {
      const title = await prepareHelmetTitle()
      if(title)
      {
        setHelmetTitle(title);
      }
             
    })();
  }, [prepareHelmetTitle]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsView = useMemo(() => {
    const columns: IEnhancedColumn<ProcessCommentManage>[] = [
      {
        key: SpecialColumnKey.Select,
        name: '',
        data: OtherColumn,
        minWidth: 30,
        onRender(item, index, column) {
          return (
            <Checkbox
              className={styles.select}
              onChange={(ev, checked) => onRowCheck(item, !!checked)}
              defaultChecked={selectedIds.some(id => id === item.id)}
            />
          );
        }
      },
      {
        key: SpecialColumnKey.Delete,
        name: '',
        onRender: (item: ProcessCommentManage) => (
          <FeedbackCenterDelete comment={item} refresh={refresh} />
        ),
        data: OtherColumn,
        minWidth: 120
      },
      {
        key: 'created',
        name: 'Created',
        data: DateTimeColumn,
        minWidth: 145
      },
      {
        key: 'createdBy',
        name: 'Created by',
        data: UserDtoColumn,
        minWidth: 220
      },
      {
        key: 'process',
        name: 'Process',
        data: ProcessesColumn,
        minWidth: 310
      },
      {
        key: 'comment',
        name: 'Comment',
        data: RichtextColumn,
        minWidth: 460
      },
      {
        key: 'status',
        name: 'Status',
        data: DropdownColumn,
        minWidth: 160
      },
      {
        key: 'isMain',
        name: 'Is Main',
        data: BooleanColumn,
        minWidth: 150
      }

    ];
    return columns;
  }, [onRowCheck, refresh, selectedIds]);

  return (
    <>
      {helmetTitle && (
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
      )}
      <HeadingWithDecoration text="Feedback Center" />
      <div className={styles.buttons}>
        <TheButton
          disabled={selectedIds.length === 0}
          iconProps={{iconName: 'delete'}}
          onClick={onOpenDelete}>
          Delete selected comments
        </TheButton>
      </div>
      <ModalWithHeader
        header={'Delete selected comments'}
        isVisible={isDeleteModal}
        dismiss={onCloseDelete}>
        <>
          There are {selectedIds.length} comments selected.
          <FormFooter onCancel={onCloseDelete} onSubmit={onDeleteConfirm} saveLabel="Delete" />
        </>
      </ModalWithHeader>
      {isLoading ? <Spinner /> : null}
      {isLoading !== undefined ? (
        <EnhancedDetailsList<ProcessCommentManage, ProcessCommentManage, ProcessCommentManage>
          columns={columnsView}
          items={comments}
        />
      ) : null}
    </>
  );
};
