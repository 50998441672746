import {IColumn} from '@fluentui/react';

import {ColumnType} from '../IEnhancedDetailsList';
import {EnhancedColumn} from './EnhancedColumn';
import { CopyButton } from '../../CopyButton';

export class CopyColumn extends EnhancedColumn {
  public static type = ColumnType.CopyLink;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 112;
  public static maxWidth = 112;

  public static renderer(value: string, column: IColumn, item?: any): JSX.Element {
    return value ? <CopyButton url={value} /> : <></>;
  }
}
