import {TheButton} from '../TheButton';
import styles from './CopyButton.module.scss';

interface IProps {
  url: string;
}


export const CopyButton = ({url}: IProps) => {

  return (
    <div className={styles.wrapperBtn}>
      <TheButton
       
        onClick={() => navigator.clipboard.writeText(url)}
        target="_blank"
        iconProps={{
          iconName: 'Copy'
        }}
        className={styles.button}
        split>
        Copy Link
      </TheButton>
    </div>
  );
};
