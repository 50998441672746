import {ProcessType} from '../enums';
import {Links, UserDto, ViewProcessCommentDto} from '../services/generated';
import {ProcessMinimum} from './ProcessMinimum';

export class ProcessCommentManage implements ViewProcessCommentDto {
  public id: number;
  public link: Links[] | null;
  public created: string;
  public comment: string | null;
  public createdBy: UserDto | null;
  public processId: number;
  public processTitle: string | null;
  public unitId: number;
  public status: string | null;
  public isMain: boolean = false;

  public get process(): ProcessMinimum {
    return new ProcessMinimum({
      id: this.processId,
      link: [],
      title: this.processTitle,
      type: ProcessType.Process,
      unitId: this.unitId,
      function: null,
      projectSizeCategories: null,
      specialities: null,
      industries: null,
      order: 0,
      masterProcessId: 0,
      parentMasterProcessId: null,
      businessLines: null
    });
  }

  constructor(comment: ViewProcessCommentDto) {
    this.id = comment.id;
    this.link = comment.link;
    this.created = comment.created;
    this.comment = comment.comment;
    this.createdBy = comment.createdBy;
    this.processId = comment.processId;
    this.processTitle = comment.processTitle;
    this.unitId = comment.unitId;
    this.status = comment.status;
    this.isMain = comment.isMain;
  }
}
