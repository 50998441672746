import {MinimumProcessDto, SlimDocumentDto} from '../services/generated';
import { AcknowledgementStatus } from '../services/generated/models/AcknowledgementStatus';
import { MyAcknowledgementDto } from '../services/generated/models/MyAcknowledgementDto';

export class MyAcknowledgementModel implements MyAcknowledgementDto {
  public id: number;
  public created : string;
  public comment: string;
  public deadline: string;
  public documents: SlimDocumentDto[] | null;
  public url: string;
  public status: AcknowledgementStatus;
  public process: MinimumProcessDto;
  public get statusString() {
    switch (this.status) {
      case AcknowledgementStatus.InProgress:
        return 'In Progress';
      case AcknowledgementStatus.Completed:
        return 'Completed';
      case AcknowledgementStatus.AfterDeadline:
        return 'After Deadline';
        case AcknowledgementStatus.Expired:
          return 'Expired';
      default:
        return '';
    }
  }

  constructor(acknowledgement: MyAcknowledgementDto) {
    this.id = acknowledgement.id;
    this.created = acknowledgement.created;
    this.comment = acknowledgement.comment;
    this.process = acknowledgement.process
    this.documents = acknowledgement.documents;
    this.url = acknowledgement.url;
    this.deadline = acknowledgement.deadline;
    this.status = acknowledgement.status;
  }
}
