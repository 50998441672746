import {FC, ReactElement, useCallback, useContext, useEffect, useState} from 'react';
import {Process, ProcessComment} from '../../../../../models';
import {PeoplePicker} from '../../../../common/PeoplePicker';
import {TheButton} from '../../../../common/TheButton';
import {UserCommentThumbStatus} from '../../../../../services/generated';
import styles from './Feedback.module.scss';
import {useComments} from '../../../../../hooks/services/useComments';
import {FeedbackDelete} from './FeedbackDelete';
import {FeedbackReplyTo} from './FeedbackReplyTo';
import {FeedbackComment} from './FeedbackComment';
import {IContextualMenuProps} from '@fluentui/react/lib/ContextualMenu';
import {
  ElevateStatusEnum,
  CanBeElevateStatusEnum,
  PermissionToCheck,
  actions
} from '../../../../../enums';
import {checkPermissions} from '../../../../../helpers/permissionsHtml';
import {useUser} from '../../../../../hooks/services/useUser';
import {useAdmin} from '../../../../../hooks/services/useAdmin';
import {TooltipHost} from '@fluentui/react';
import {RaiseToOpexModal} from '../../../../common/ProcessSections/RaiseToOpexModal';
import {UserContext} from '../../../../../providers';
import {inquiryImpactOptions} from '../../../../../enums/InquiryImpact';
import {inquiryCategoryOptions} from '../../../../../enums/InquiryCategory';
import {OpexDto} from '../../../../../services/generated/models/OpexDto';

interface IFeedBackCommentWithReplies {
  comment: ProcessComment;
  process: Process;
  unitId: number;
  allReplyTo: ProcessComment[] | undefined;
  refresh: (skipLoading?: boolean) => void;
}

export const FeedBackCommentWithReplies: FC<IFeedBackCommentWithReplies> = ({
  comment,
  allReplyTo,
  process,
  unitId,
  refresh
}) => {
  const {toggleLike, toggleDislike, elevateToProcessComment, resolveComment, raiseComment} =
    useComments();
  const {getUserPermissionsPage} = useUser();
  const {getGlobalSettings} = useAdmin();
  const {currentUserData} = useContext(UserContext);
  const [category, setCategory] = useState<number>(1);
  const [initiator, setInitiator] = useState<string>(
    currentUserData ? currentUserData?.localAccountId : ''
  );
  const [impact, setImpact] = useState<number>(0);

  const [userAbleToAdvancedActions, setUserAbleToAdvancedActions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePending, setIsDeletePending] = useState(false);
  const [isReplyToPending, setIsReplyToPending] = useState(false);
  const [raiseToOpexFeatureEnabled, setRaiseToOpexFeatureEnabled] = useState(false);
  const [isModalVisibie, setIsModalVisible] = useState(false);

  const isAvailableToElevate = comment.canBeElevateStatus !== CanBeElevateStatusEnum.None;
  const elevatedStatus = comment.elevatedStatus;
  const isElevated = elevatedStatus !== ElevateStatusEnum.None;
  const isResolved = comment.resolved;
  const isBtnDisabled = isLoading || isResolved || isElevated;
  let defaultIOpenStatus = !isResolved && !isElevated;
  const isElevatedNotToOpex = elevatedStatus !== ElevateStatusEnum.None && elevatedStatus !== ElevateStatusEnum.ToOpex;

  const [show, setShow] = useState(defaultIOpenStatus);

  const level = 1;
  const onLike = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await toggleLike(comment.id);
      refresh(true);
      setIsLoading(false);
    })();
  }, [comment.id, refresh, toggleLike]);
  const onDislike = useCallback(() => {
    (async () => {
      setIsLoading(true);
      await toggleDislike(comment.id);
      refresh(true);
      setIsLoading(false);
    })();
  }, [comment.id, refresh, toggleDislike]);

  const onDeleteOpen = useCallback(() => setIsDeletePending(true), []);
  const onDeleteClose = useCallback(() => setIsDeletePending(false), []);
  const onDeleted = useCallback(() => {
    refresh();
  }, [refresh]);

  const prepareInquiryTitle = (process: Process): string => {
    if (process?.unit?.title) {
      const correctTitle =
        process.unit.parentTitle && process.unit.unitTypeId === 2
          ? `${process.unit.parentTitle} ${process.unit.title}`
          : process.unit.title;
      return `IMS feedback form: ${correctTitle} ${process.title}`;
    }
    return 'IMS feedback form:';
  };

  const onModalClose = useCallback(() => {
    setCategory(inquiryCategoryOptions[1].key as number);
    setInitiator(currentUserData ? currentUserData.localAccountId : '');
    setImpact(inquiryImpactOptions[0].key as number);
    setIsModalVisible(false);
  }, [currentUserData]);

  const onModalConfirm = useCallback(async () => {
    setIsLoading(true);
    setIsModalVisible(false);
    const opexDto: OpexDto = {
      inquiryTitle: prepareInquiryTitle(process),
      inquiryDescription: comment.comment ? comment.comment : '',
      inquiryCategory: category,
      initiatorAzureId: initiator,
      inquiryImpact: impact,
      processID: process.id.toString()
    };
    var response = await raiseComment(comment.id, opexDto);
    if(response.apiCode === 'ApiError')
    {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    else
    {
      refresh();
    }
    setIsLoading(false);
  }, [category, initiator, impact, process, comment, raiseComment,refresh]);

  const onReplyToOpen = useCallback(() => setIsReplyToPending(!isReplyToPending), [isReplyToPending]);
  const showfeedbacks = useCallback(() => setShow(!show), [show]);

  const handleCategory = (category: number) => {
    setCategory(category);
  };

  const handleInitiator = (userAzureId: string) => {
    setInitiator(userAzureId);
  };

  const handleImpact = (impact: number) => {
    setImpact(impact);
  };

  useEffect(() => {
    (async () => {
      const settingsResponse = await getGlobalSettings();
      if (settingsResponse?.result) {
        const raiseToOpexFeatureEnabled = settingsResponse.result.find(
          x => x.name === 'raiseToOpex'
        );
        if (raiseToOpexFeatureEnabled) {
          setRaiseToOpexFeatureEnabled(raiseToOpexFeatureEnabled.value === 'true');
        }
      }

      const response = await getUserPermissionsPage(unitId, process.id);
      if (response?.result) {
        const canStillAccess = checkPermissions(
          PermissionToCheck.ElevateOrResolveComment,
          actions.UPDATE,
          response.result
        );
        setUserAbleToAdvancedActions(canStillAccess);
      } else {
        setUserAbleToAdvancedActions(false);
      }
    })();
  }, [allReplyTo, comment.id, getUserPermissionsPage, process, unitId, getGlobalSettings]);

  const generateReplies = (
    commentId: number,
    repliesComment: ProcessComment[] | undefined,
    level: number
  ): JSX.Element[] | ReactElement => {
    if (!repliesComment) {
      return <></>;
    }
    const repliesToComment = repliesComment.filter(r => r.replyToId === commentId);
    const restRepliesComments = repliesComment.filter(x => x.replyToId !== commentId);
    const repliesLen = repliesToComment.length;
    if (repliesLen === 0) {
      return <></>;
    }
    return repliesToComment
      .sort((a, b) => (a.created > b.created ? 1 : -1))
      .map(x => (
        <div className={styles.wrapper2} key={x.id}>
          <FeedbackComment
            key={x.id}
            allReplyTo={restRepliesComments}
            comment={x}
            processId={process.id}
            refresh={refresh}
            level={level}
            isResolved={isResolved}
          />
          {generateReplies(x.id, restRepliesComments, level + 1)}
        </div>
      ));
  };

  const countReplies = (
    commentId: number,
    repliesComment: ProcessComment[] | undefined
  ): number => {
    if (repliesComment) {
      const repliesToComment = repliesComment.filter(r => r.replyToId === commentId);
      const repliesLen = repliesToComment.length;
      return repliesLen;
    } else {
      return 0;
    }
  };

  const countRepliesLen = useCallback(
    () => !!countReplies(comment.id, allReplyTo),
    [allReplyTo, comment.id]
  );

  let disclaimerText: JSX.Element | undefined;
  let stringPlaceValue = '';
  let elevatedOrSent = '';

  if (elevatedStatus === ElevateStatusEnum.ToDivision) {
    stringPlaceValue = 'Division level';
    elevatedOrSent = 'elevated';
  } else if (elevatedStatus === ElevateStatusEnum.ToOpex) {
    stringPlaceValue = 'OPEX';
    elevatedOrSent = 'sent';
  } else if (elevatedStatus === ElevateStatusEnum.ToParent) {
    stringPlaceValue = 'Parent unit';
    elevatedOrSent = 'elevated';
  }

  if (isElevated) {
    if (isResolved) {
      disclaimerText = (
        <div className={styles.feedbackDisclaimer}>
          Comment was {elevatedOrSent} and resolved on {stringPlaceValue}.
        </div>
      );
    } else {
      disclaimerText = (
        <div className={styles.feedbackDisclaimer}>
          Comment was {elevatedOrSent} to {stringPlaceValue} for processing.
        </div>
      );
    }
  }
  else if (isResolved) {
    disclaimerText = (
      <div className={styles.feedbackDisclaimer}>
        Comment was resolved.
      </div>
    );
  }
  

  const onElevateComment = useCallback(
    (elevateTo: ElevateStatusEnum) => {
      (async () => {
        setIsLoading(true);
        const commentData = {
          commentId: comment.id,
          processId: process.id,
          elevatedStatus: elevateTo
        };
        await elevateToProcessComment(commentData);
        refresh();
        setIsLoading(false);
      })();
    },
    [comment.id, elevateToProcessComment, process, refresh]
  );

  const menuProps: IContextualMenuProps = {
    items: [
      {
        key: 'elevateParent',
        text: 'Parent',
        iconProps: {iconName: 'TriangleSolidUp12'},
        onClick: () => onElevateComment(ElevateStatusEnum.ToParent),
        disabled:
          comment.canBeElevateStatus === CanBeElevateStatusEnum.None ||
          comment.canBeElevateStatus === CanBeElevateStatusEnum.ToDivision
      },
      {
        key: 'elevateDivision',
        text: 'Division',
        iconProps: {iconName: 'TriangleUp12'},
        onClick: () => onElevateComment(ElevateStatusEnum.ToDivision),
        disabled:
          comment.canBeElevateStatus === CanBeElevateStatusEnum.None ||
          comment.canBeElevateStatus === CanBeElevateStatusEnum.ToParent
      }
    ]
  };

  const onResolveComment = useCallback(() => {
    const commentId = comment.id;
    (async () => {
      setIsLoading(true);
      await resolveComment(commentId);
      refresh();
      setIsLoading(false);
    })();
  }, [comment.id, refresh, resolveComment]);

  let actionPermittedBtns: JSX.Element | undefined;
  actionPermittedBtns = (
    <>
      {userAbleToAdvancedActions && (
        <TheButton
          onClick={onResolveComment}
          primary
          disabled={isBtnDisabled}
          iconProps={{iconName: 'ChatInviteFriend'}}>
          Resolve Comment
        </TheButton>
      )}

      {raiseToOpexFeatureEnabled && (userAbleToAdvancedActions || comment.canDeletedByMe) && (
        <TooltipHost content={'Inquiry will be created in OPEX and sent for resolution'}>
          <TheButton
            onClick={() => {
              setIsModalVisible(true);
            }}
            primary
            disabled={isBtnDisabled}
            iconProps={{iconName: 'Rocket'}}>
            Create Inquiry
          </TheButton>
        </TooltipHost>
      )}

      {userAbleToAdvancedActions && (
        <TheButton
          onClick={() => {}}
          primary
          split
          disabled={isBtnDisabled || !isAvailableToElevate}
          iconProps={{iconName: 'Manufacturing'}}
          menuProps={menuProps}>
          Elevate To
        </TheButton>
      )}
    </>
  );

  const commentActionBtns = (
    <>
      <TheButton
        onClick={onReplyToOpen}
        primary
        disabled={isBtnDisabled}
        iconProps={{iconName: 'Reply'}}>
        {`${isReplyToPending ? 'Hide ' : ''}Reply To`}
      </TheButton>
      <TheButton
        onClick={showfeedbacks}
        primary
        disabled={isLoading || isElevatedNotToOpex || !countRepliesLen()}
        iconProps={{iconName: show ? 'ChevronUp' : 'ChevronDown'}}>
        {`${show ? 'Hide' : 'Show'} Replies`}
      </TheButton>
    </>
  );

  const thumbsBtns = (
    <div className={styles.thumbs}>
      <TheButton
        disabled={isBtnDisabled}
        onClick={onLike}
        iconProps={{
          iconName:
            comment.userCommentThumbStatus === UserCommentThumbStatus.ThumbUp ? 'LikeSolid' : 'Like'
        }}>
        {comment.thumbUpCount}
      </TheButton>
      <TheButton
        disabled={isBtnDisabled}
        onClick={onDislike}
        iconProps={{
          iconName:
            comment.userCommentThumbStatus === UserCommentThumbStatus.ThumbDown
              ? 'DislikeSolid'
              : 'Dislike'
        }}>
        {comment.thumbDownCount}
      </TheButton>
    </div>
  );

  let deleteBtn: JSX.Element | undefined;
  if (comment.canDeletedByMe) {
    deleteBtn = (
      <TheButton
        onClick={onDeleteOpen}
        disabled={isBtnDisabled}
        classNameButton={styles.delete}
        iconProps={{iconName: 'Trash'}}>
        Delete
      </TheButton>
    );
  }

  const commentAndPersonText = (
    <>
      <PeoplePicker isReadonly defaultSelectedPersonId={comment.createdBy?.azureId} />
      <div
        className={`sun-editor sun-editor-editable can-copy ${styles.mainComment} ${
          styles.sunEditor
        } ${isResolved ? styles.grayOut : undefined}`}
        dangerouslySetInnerHTML={{
          __html: comment.comment || ''
        }}
      />
    </>
  );

  let repliesContent: JSX.Element | undefined;
  if (show) {
    repliesContent = <>{generateReplies(comment.id, allReplyTo, level)}</>;
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <div className={styles.date}>{comment.created.replace('T', ' ').split('.')[0]}</div>
          {thumbsBtns}
          {deleteBtn}
        </div>
        <div className={styles.commentContainer}>
          {disclaimerText}
          {commentAndPersonText}
          <div className={styles.buttonContainer}>
            {actionPermittedBtns}
            {commentActionBtns}
          </div>
          <FeedbackReplyTo
          processId={process.id}
          comment={comment}
          isOpen={isReplyToPending}
          refresh={onDeleted}
        />
        </div>

        <FeedbackDelete
          comment={comment}
          isOpen={isDeletePending}
          onClose={onDeleteClose}
          refresh={onDeleted}
        />
      </div>
      {repliesContent}
      <RaiseToOpexModal
        comment={comment.comment ? comment.comment : ''}
        userAzureId={currentUserData ? currentUserData.localAccountId : ''}
        isVisible={isModalVisibie}
        isLoading={isLoading}
        title={prepareInquiryTitle(process)}
        handleCategory={handleCategory}
        handleInitiator={handleInitiator}
        handleImpact={handleImpact}
        onClose={onModalClose}
        onConfirm={onModalConfirm}></RaiseToOpexModal>
    </>
  );
};
