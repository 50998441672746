import styles from './NormalDiagram.module.scss';
import {DiagramProcessGroup, MinimalProcessForDiagramDto} from '../../../services/generated';
import {Dropdown, IDropdownOption, Spinner} from '@fluentui/react';
import {FC} from 'react';
import {Images} from '../../../assets/img';
import {IProcessOption} from './useDiagram';
import {NavLink} from 'react-router-dom';
import {ProcessType} from '../../../enums';
import {TheButton} from '../TheButton';
import DoubleScrollbar from 'react-double-scrollbar'
import { DropdownWithSearch } from '../DropdownWithSearch';

export interface INormalValue {
  group: DiagramProcessGroup | null;
  childId: number;
  order: number;
  child?: MinimalProcessForDiagramDto | null;
}

interface INormalDiagramProps {
  unitId: number;
  isEdit: boolean;
  isProcessesLoading: boolean;
  processTitle: string;
  processesOptions: IProcessOption[];
  processesOptionsFiltered: IProcessOption[];
  normalValues: INormalValue[];
  setNormalValues: (values: INormalValue[]) => void;
}

export const NormalDiagram: FC<INormalDiagramProps> = ({
  unitId,
  isEdit,
  processTitle,
  isProcessesLoading,
  processesOptions,
  processesOptionsFiltered,
  normalValues,
  setNormalValues
}) => {
  const mainNodes = normalValues
    .filter(node => node.group === DiagramProcessGroup.MainNode)
    .sort((a, b) => a.order - b.order);
  const mainNodesMaxOrder = mainNodes.length ? mainNodes[mainNodes.length - 1].order : -1;
  const supportingNodes = normalValues
    .filter(node => node.group === DiagramProcessGroup.SupportingNode)
    .sort((a, b) => a.order - b.order);
  const supportingNodesMaxOrder = supportingNodes.length
    ? supportingNodes[supportingNodes.length - 1].order
    : -1;
  const onProcessSelect = (option: IDropdownOption, group: DiagramProcessGroup, order: number) => {
    const filteredValues = normalValues.filter(
      value => !(value.group === group && value.order === order)
    );
    filteredValues.push({
      childId: option.key as number,
      group,
      order,
      child: undefined
    });
    setNormalValues(filteredValues);
  };
  const onRemove = (order: number, group: DiagramProcessGroup) => {
    const filtered = normalValues.filter(
      value => !(value.order === order && value.group === group)
    );
    const reOrdered = filtered.map(value => ({
      ...value,
      order: value.order > order ? value.order - 1 : value.order
    }));
    setNormalValues(reOrdered);
  };
  const drawChildren = (processId: number) => {
    const process = processesOptions.find(item => item.key === processId);
    return isProcessesLoading ? (
      <Spinner />
    ) : (
      (isEdit ? processesOptions : processesOptionsFiltered)
        .filter(item => item.data.parentMasterProcessId === process?.data.masterProcessId)
        .map(item => {
          if (item.data.type === ProcessType.Process) {
            return (
              <NavLink
                key={item.key}
                className={`${styles.child} ${styles.link}`}
                to={`/unit/${unitId}/process/${item.key}`}>
                {item.text}
              </NavLink>
            );
          }
          return (
            <div className={styles.child} key={item.key}>
              {item.text}
            </div>
          );
        })
    );
  };

  const mainNodesFiltered = mainNodes.filter((node, i) => {
    const process = processesOptions.find(item => item.key === node.childId);
    if (
      !isEdit &&
      !processesOptionsFiltered.find(item => item.key === node.childId) &&
      !processesOptionsFiltered.find(
        item => item.data.parentMasterProcessId === process?.data.masterProcessId
      )
    )
      return false;
    return true;
  });

  return (
    <div className={styles.wrapper}>
      <DoubleScrollbar>
      <div className={styles.supportingNodes}>
        {supportingNodes.map(node => {
          const process = (isEdit ? processesOptions : processesOptionsFiltered).find(
            item => item.key === node.childId
          );
          if (!process) return null;
          const isProcess = process?.data.type === ProcessType.Process;
          const wrapperProps = {
            key: `${node.childId}${node.order}`,
            to: `/unit/${unitId}/process/${node.childId}`,
            className: `${styles.supportingNode}${isProcess ? ` ${styles.link}` : ''}`
          };
          const inner = (
            <>
              {isProcessesLoading ? (
                <Spinner />
              ) : isEdit ? (
                <DropdownWithSearch
                  className={styles.dropdown}
                  defaultSelectedKey={node.childId}
                  options={processesOptions}
                  onChange={(e, option) => {
                    if (option)
                      onProcessSelect(option, DiagramProcessGroup.SupportingNode, node.order);
                  }}
                />
              ) : (
                <div>
                  {node.child?.deleted ? (
                    <span className={styles.deleted}>{node.child.title}</span>
                  ) : (
                    processesOptions.find(option => option.key === node.childId)?.text
                  )}
                </div>
              )}
            </>
          );
          return isEdit ? (
            <div {...wrapperProps}>
              {inner}
              <TheButton
                className={styles.remove}
                isIcon
                iconProps={{iconName: 'Delete'}}
                onClick={() => {
                  if (node.group) onRemove(node.order, node.group);
                }}
              />
            </div>
          ) : isProcess ? (
            <NavLink {...wrapperProps}>{inner}</NavLink>
          ) : (
            <div {...wrapperProps}>{inner}</div>
          );
        })}
        {isEdit ? (
          <div className={styles.supportingNode}>
            {isProcessesLoading ? (
              <Spinner />
            ) : (
              <DropdownWithSearch
                className={styles.dropdown}
                key={supportingNodesMaxOrder + 1}
                options={processesOptions}
                onChange={(e, option) => {
                  if (option)
                    onProcessSelect(
                      option,
                      DiagramProcessGroup.SupportingNode,
                      supportingNodesMaxOrder + 1
                    );
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      <div className={styles.mainWrapper}>
        <div className={styles.longArrow} />
        <div className={styles.leftWrapper}>
          <div className={styles.boxedCircle}>
            <div className={styles.img}>
              <img alt="diagram icon" src={Images.think} />
            </div>
            <div className={`${styles.arrow} ${styles.arrow0}`}></div>
            <div className={`${styles.arrow} ${styles.arrow1}`}></div>
            <div className={`${styles.arrow} ${styles.arrow2}`}></div>
            <div className={styles.arrowBottom}></div>
          </div>
          <div className={styles.text}>{processTitle}</div>
        </div>
        <div
          className={styles.mainNodes}
          style={{
            gridTemplateColumns: `repeat(${mainNodesFiltered.length + (isEdit ? 1 : 0)}, 1fr)`
          }}>
          {mainNodesFiltered.map((node, i) => {
            const process = processesOptions.find(item => item.key === node.childId);
            if (
              !isEdit &&
              !processesOptionsFiltered.find(item => item.key === node.childId) &&
              !processesOptionsFiltered.find(
                item => item.data.parentMasterProcessId === process?.data.masterProcessId
              )
            )
              return null;
            const isProcess = process?.data.type === ProcessType.Process;
            const wrapperProps = {
              key: `${node.childId}${node.order}`,
              to: `/unit/${unitId}/process/${node.childId}`,
              className: `${styles.mainNode}${isProcess ? ` ${styles.link}` : ''}`,
              style: {
                zIndex: 100 - i
              }
            };
            const inner = (
              <>
                {isProcessesLoading ? (
                  <Spinner />
                ) : isEdit ? (
                  <DropdownWithSearch
                    className={styles.dropdown}
                    defaultSelectedKey={node.childId}
                    options={processesOptions}
                    onChange={(e, option) => {
                      if (option) onProcessSelect(option, DiagramProcessGroup.MainNode, node.order);
                    }}
                  />
                ) : (
                  <div>
                    {node.child?.deleted ? (
                      <span className={styles.deleted}>{node.child.title}</span>
                    ) : (
                      processesOptions.find(option => option.key === node.childId)?.text
                    )}
                  </div>
                )}
              </>
            );
            return (
              <div className={styles.column} key={`${node.childId}${node.order}`}>
                {isEdit ? (
                  <div {...wrapperProps}>
                    {inner}
                    <TheButton
                      className={styles.remove}
                      isIcon
                      iconProps={{iconName: 'Delete'}}
                      onClick={() => {
                        if (node.group) onRemove(node.order, node.group);
                      }}
                    />
                  </div>
                ) : isProcess ? (
                  <NavLink {...wrapperProps}>{inner}</NavLink>
                ) : (
                  <div {...wrapperProps}>{inner}</div>
                )}
                {drawChildren(node.childId)}
              </div>
            );
          })}
          {isEdit ? (
            <div className={styles.column}>
              <div className={styles.mainNode}>
                {isProcessesLoading ? (
                  <Spinner />
                ) : (
                  <DropdownWithSearch
                    className={styles.dropdown}
                    key={mainNodesMaxOrder + 1}
                    options={processesOptions}
                    onChange={(e, option) => {
                      if (option)
                        onProcessSelect(
                          option,
                          DiagramProcessGroup.MainNode,
                          mainNodesMaxOrder + 1
                        );
                    }}
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      </DoubleScrollbar>
    </div>
  );
};
