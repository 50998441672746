import {TheButton} from '../TheButton';
import styles from './SplitOpenCopyButton.module.scss';

interface IProps {
  url: string;
}

export const SplitOpenCopyButton = ({url}: IProps) => {

  const extensionRegex = url.match(/\.(\w{2,5})(?:$|\?)/);
  const extension = extensionRegex ? extensionRegex[1] : null;
  const openLink =
    extension === 'pdf' || !extension ? url : `${url}${url.indexOf('?') === -1 ? '?' : '&'}web=1`;

  const copyLink: () => void = async () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.wrapperBtn}>
      <TheButton
        href={openLink}
        onClick={() => {}}
        target="_blank"
        menuProps={{
          items: [
            {
              key: 'CopyLink',
              text: 'Copy Link',
              iconProps: {
                iconName: 'Copy'
              },
              onClick: () => copyLink()
            }
          ]
        }}
        iconProps={{
          iconName: 'Page'
        }}
        classNameButton={`${styles.button} ${styles.splitButton}`}
        className={styles.button}
        split>
        Open
      </TheButton>
    </div>
  );
};
