import {Checkbox, Icon, TooltipHost} from '@fluentui/react';

import {Document, DocumentInUnit} from '../../../models';
import {DocumentSystem} from '../../../models/DocumentSystem';
import {
  BooleanColumn,
  DateTimeColumn,
  OtherColumn,
  ProcessesColumn,
  SelectColumn,
  ToggleColumn
} from '../EnhancedDetailsList';
import {MultiselectColumn} from '../EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../EnhancedDetailsList/IEnhancedDetailsList';
import {OrderInput} from '../OrderInput';
import {CustomToggle} from './CustomToggle';
import {OpenPDCColumn} from '../EnhancedDetailsList/ColumnTypes/OpenPDCColumn';
import {TheButton} from '../TheButton';
import { KeywordsColumn } from '../EnhancedDetailsList/ColumnTypes/KeywordsColumn';

const isSmart = typeof new URLSearchParams(document.location.search).get('smart') === 'string';

function columnSmartFilter(
  column: IEnhancedColumn<DocumentSystem> | IEnhancedColumn<DocumentInUnit> | undefined
) {
  return !!column && (isSmart || ['ownerProcessId', 'id'].indexOf(column.key) === -1);
}

function mapToClass(item: {[key: string]: any}) {
  return new DocumentSystem(item as DocumentSystem);
}

function getPdcOperatingUnitValue(pdcOperatingUnit: string | null | undefined) {
  return pdcOperatingUnit ? pdcOperatingUnit.split(';').map(x => x.trim()) : undefined;
}

const columnsViewList = [
  ColumnKey.Url,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  ColumnKey.Title,
  ColumnKey.DocumentId,
  ColumnKey.ContentTypeString,
  ColumnKey.DocumentKind,
  ColumnKey.Revision,
  ColumnKey.OperatingUnit,
  ColumnKey.FunctionString,
  'businessLines',
  'industries',
  'specialities',
  'projectSizeCategories',
  ColumnKey.DocumentOwner,
  ColumnKey.ApprovedDate,
  ColumnKey.Auditable,
  ColumnKey.RelevantTo,
  ColumnKey.Language,
  ColumnKey.SecurityLevel,
  ColumnKey.KeyWords
];
const columnsEditList = [
  ColumnKey.Removed,
  ColumnKey.Active,
  ColumnKey.Order,
  ColumnKey.AuditableEdit,
  ColumnKey.Edit,
  ColumnKey.Url,
  ColumnKey.Id,
  ColumnKey.OwnerProcessId,
  ColumnKey.Title,
  ColumnKey.DocumentId,
  ColumnKey.ContentTypeString,
  ColumnKey.DocumentKind,
  ColumnKey.Revision,
  ColumnKey.OperatingUnit,
  ColumnKey.RelevantTo,
  ColumnKey.FunctionString,
  'businessLines',
  'industries',
  'specialities',
  'projectSizeCategories',
  ColumnKey.DocumentOwner,
  ColumnKey.ApprovedDate,
  ColumnKey.Language,
  ColumnKey.SecurityLevel,
  ColumnKey.KeyWords
];

const columnsAddList = [
  ColumnKey.Url,
  ColumnKey.Id,
  ColumnKey.Title,
  ColumnKey.DocumentId,
  ColumnKey.OperatingUnit,
  ColumnKey.ContentTypeString,
  ColumnKey.DocumentKind,
  ColumnKey.FunctionString,
  'businessLines',
  'industries',
  'specialities',
  'projectSizeCategories',
  ColumnKey.DocumentOwner,
  ColumnKey.ApprovedDate,
  ColumnKey.Revision,
  ColumnKey.Language,
  ColumnKey.RelevantTo,
  ColumnKey.SecurityLevel,
  ColumnKey.KeyWords
];

const columnsAcknowledgement = [
  'checkedAcknowledgementItem',
  ColumnKey.Id,
  ColumnKey.Title,
  ColumnKey.DocumentId,
  ColumnKey.OperatingUnit,
  ColumnKey.ContentTypeString,
  ColumnKey.DocumentKind,
  ColumnKey.DocumentOwner,
  ColumnKey.ApprovedDate,
  ColumnKey.Revision,
  ColumnKey.Language
];

const columnsCenterViewList = [...columnsViewList];
columnsCenterViewList.splice(5, 0, SpecialColumnKey.EditDocIdLand);
columnsCenterViewList.splice(6, 0, 'processes');

const columnsUnitCenterViewList = [...columnsViewList];
columnsUnitCenterViewList.splice(0, 0, SpecialColumnKey.Select);
columnsUnitCenterViewList.splice(6, 0, 'processes');



 function getColumnsAuditable() {
  const cols: IEnhancedColumn<DocumentInUnit>[] = [
    {
      key: ColumnKey.Title,
      name: 'Title',
      minWidth: 330,

      flexGrow: 1,
      onExportToExcel: (item, column) => {
        return {
          text: item.title,
          link: item.url
        };
      }
    },
    {
      key: ColumnKey.DocumentId,
      name: 'Document ID',
      minWidth: 135,
      onRender(item) {
        return (
          <>
            {item.documentId}
            {item.inReview && (
              <TooltipHost content="New revision is pending">
                <Icon styles={{root: {paddingLeft: '5px', color: 'red'}}} iconName="Sync" />
              </TooltipHost>
            )}
          </>
        );
      }
    },
    {
      key: ColumnKey.ContentTypeString,
      name: 'Content Type',
      data: SelectColumn,
      minWidth: 135
    },
    {
      key: ColumnKey.Processes,
      name: 'Process(es)',
      data: ProcessesColumn,
      minWidth: 200,
      flexGrow: 1
    },
    {
      key: ColumnKey.OperatingUnit,
      name: 'Operating Unit',
      data: SelectColumn,
      minWidth: 150
    },

    {
      key: ColumnKey.Auditable,
      name: 'Auditable',
      data: BooleanColumn,
      minWidth: 110
    }
];
return cols;
}

function getColumns(
  changedDocuments?: DocumentSystem[],
  ownerProcessId?: number,
  pdcOperatingUnit?: string | null,
  onEditClick?: (item: DocumentSystem) => void,
  onRowCheck? : (item: DocumentSystem, checked: boolean) => void
) {
  const cols: IEnhancedColumn<DocumentSystem>[] = [
    {
      key: ColumnKey.Removed,
      name: '🗑️',
      onRender: (item: DocumentSystem) =>
        item.ownerProcessId === ownerProcessId ? (
          <CustomToggle<DocumentSystem>
            property={'removed'}
            changedItems={changedDocuments}
            item={item}
            mapToClass={mapToClass}
          />
        ) : (
          <></>
        ),
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle documents to be removed'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Active,
      name: '👁',
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost content={'Toggle documents to be active (visible)'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      onRender: (item: DocumentSystem) => (
        <CustomToggle<DocumentSystem>
          property={'active'}
          changedItems={changedDocuments}
          item={item}
          mapToClass={mapToClass}
        />
      ),
      data: ToggleColumn
    },
    {
      key: 'checkedAcknowledgementItem',
      name: '+',
      onRenderHeader: (props, defaultRender) =>
        defaultRender ? (
          <TooltipHost
            content={'Toggle documents the documents to be sent for confirmation/acknowledgement'}>
            {defaultRender(props)}
          </TooltipHost>
        ) : (
          <></>
        ),
      onRender: (item: DocumentSystem) => (
        <CustomToggle<DocumentSystem>
          property={'checkedAcknowledgementItem'}
          changedItems={changedDocuments}
          item={item}
          mapToClass={mapToClass}
        />
      ),
      data: ToggleColumn
    },
    {
      key: ColumnKey.Order,
      name: 'Order',
      isResizable: false,
      minWidth: 80,
      maxWidth: 80,
      onRender: (item: DocumentSystem) =>
        changedDocuments ? (
          <OrderInput
            defaultValue={item.order}
            onChange={value => {
              const changedDocument = changedDocuments.filter(
                document =>
                  document.id === item.id && document.ownerProcessId === item.ownerProcessId
              )[0];
              if (changedDocument) {
                changedDocument.order = value;
              } else {
                changedDocuments.push({...item, order: value});
              }
            }}
          />
        ) : (
          <></>
        ),
      data: OtherColumn
    },
    {
      key: SpecialColumnKey.AuditableEdit,
      name: 'Auditable',
      minWidth: 90,
      onRender: (item: DocumentSystem) => (
        <CustomToggle<DocumentSystem>
          property={'auditable'}
          changedItems={changedDocuments}
          item={item}
          mapToClass={mapToClass}
        />
      ),
      data: OtherColumn
    },
    {
      key: ColumnKey.Url,
      name: '',
      data: OpenPDCColumn
    },
    {
      key: ColumnKey.Id,
      exportToExcel: true,
      name: 'ID',
      minWidth: 130
    },
    {
      key: ColumnKey.OwnerProcessId,
      exportToExcel: true,
      name: 'Process ID',
      minWidth: 130
    },
    {
      key: ColumnKey.Title,
      exportToExcel: true,
      name: 'Title',
      minWidth: 330,
      flexGrow: 1
    },
    {
      key: ColumnKey.DocumentId,
      exportToExcel: true,
      name: 'Document ID',
      minWidth: 135,
      onRender(item) {
        return (
          <>
            {item.documentId}
            {item.inReview && (
              <TooltipHost content="New revision is pending">
                <Icon styles={{root: {paddingLeft: '5px', color: 'red'}}} iconName="Sync" />
              </TooltipHost>
            )}
          </>
        );
      }
    },
    {
      key: ColumnKey.ContentTypeString,
      exportToExcel: true,
      name: 'Content Type',
      data: SelectColumn,
      minWidth: 135
    },
    {
      key: ColumnKey.IsBroken,
      name: 'Is Broken',
      minWidth: 120,
      data: BooleanColumn
    },
    {
      key: ColumnKey.DocumentKind,
      exportToExcel: true,
      name: 'Document Kind',
      data: SelectColumn,
      minWidth: 150
    },
    {
      key: ColumnKey.Revision,
      exportToExcel: true,
      name: 'Revision',
      data: SelectColumn,
      minWidth: 110
    },
    {
      key: ColumnKey.RelevantTo,
      exportToExcel: true,
      name: 'Related To',
      minWidth: 135
    },
    {
      key: ColumnKey.Auditable,
      exportToExcel: true,
      name: 'Auditable',
      data: BooleanColumn,
      minWidth: 110
    },
    {
      key: ColumnKey.OperatingUnit,
      exportToExcel: true,
      name: 'Operating Unit',
      data: SelectColumn,
      defaultFilterValue: getPdcOperatingUnitValue(pdcOperatingUnit),
      minWidth: 150
    },
    {
      key: ColumnKey.FunctionString,
      exportToExcel: true,
      name: 'Function',
      data: SelectColumn,
      minWidth: 105
    },
    {
      key: 'businessLines',
      exportToExcel: true,
      name: 'Business Line',
      data: MultiselectColumn,
      minWidth: 190
    },
    {
      key: 'industries',
      exportToExcel: true,
      name: 'Industries',
      data: MultiselectColumn,
      minWidth: 130
    },
    {
      key: 'specialities',
      exportToExcel: true,
      name: 'Specialty',
      data: MultiselectColumn,
      minWidth: 110
    },
    {
      key: 'projectSizeCategories',
      exportToExcel: true,
      name: 'Project Size Category',
      data: MultiselectColumn,
      minWidth: 190
    },
    {
      key: ColumnKey.DocumentOwner,
      exportToExcel: true,
      name: 'Document Owner',
      minWidth: 160
    },
    {
      key: ColumnKey.ApprovedDate,
      exportToExcel: true,
      name: 'Approved Date',
      minWidth: 145,
      data: DateTimeColumn
    },
    {
      key: ColumnKey.Language,
      exportToExcel: true,
      name: 'Language',
      data: SelectColumn,
      minWidth: 110
    },
    {
      key: ColumnKey.SecurityLevel,
      exportToExcel: true,
      name: 'Security Level',
      data: SelectColumn,
      minWidth: 140
    },
    {
      key: ColumnKey.KeyWords,
      exportToExcel: true,
      name: 'Keywords',
      minWidth: 190,
      data: KeywordsColumn
    },
    {
      key: SpecialColumnKey.Edit,
      name: '',
      onRender: (item: DocumentSystem) => {
        return (
          <TheButton
            iconProps={{
              iconName: 'Edit'
            }}
            onClick={() => (onEditClick ? onEditClick(item) : undefined)}
            disabled={false}
            primary>
            Set Attributes
          </TheButton>
        );
      },
      data: OtherColumn,
      minWidth: 160
    }
  ];
  return cols;
}


function getColumnsCenter(onEditDetailsClick?: (item: DocumentInUnit) => void, onRowCheck?:(item: DocumentInUnit, isChecked: boolean) => void) {
  const cols: IEnhancedColumn<DocumentInUnit>[] =
    getColumns() as any[] as IEnhancedColumn<DocumentInUnit>[];
  let newCols = [
    ...cols,
    {
      key: SpecialColumnKey.Select,
      name: '',
      data: OtherColumn,
      minWidth: 40,
      onRender(item: DocumentInUnit) {
        return (
          <Checkbox
            onChange={(ev, checked) => onRowCheck ? onRowCheck(item, !!checked) : undefined}
            defaultChecked={false}
          />
        );
      }
    },
    {
      key: SpecialColumnKey.EditDocIdLand,
      name: 'Edit Doc Id and Lang Code',
      onRender: (item: DocumentInUnit) => {
        return (
          <TheButton
            iconProps={{
              iconName: 'Edit'
            }}
            onClick={() => (onEditDetailsClick ? onEditDetailsClick(item) : undefined)}
            disabled={false}
            primary>
            Edit details
          </TheButton>
        );
      },
      data: OtherColumn,
      minWidth: 200
    }
  ];
  newCols.splice(4, 0, {
    key: 'processes',
    exportToExcel: true,
    name: 'Process(es)',
    minWidth: 400,
    data: ProcessesColumn
  });
  return newCols;
}



export function getColumnsView(changedDocuments?: DocumentSystem[], ownerProcessId?: number) {
  return columnsViewList
    .map(columnKey =>
      getColumns(changedDocuments, ownerProcessId).find(column => column.key === columnKey)
    )
    .filter(columnSmartFilter) as IEnhancedColumn<DocumentSystem>[];
}

export function getColumnsEdit(
  changedDocuments?: DocumentSystem[],
  ownerProcessId?: number,
  onEditClick?: (item: DocumentSystem) => void
) {
  return columnsEditList
    .map(columnKey =>
      getColumns(changedDocuments, ownerProcessId, undefined, onEditClick).find(
        column => column.key === columnKey
      )
    )
    .filter(columnSmartFilter) as IEnhancedColumn<DocumentSystem>[];
}

export function getColumnsAdd(
  changedDocuments?: DocumentSystem[],
  ownerProcessId?: number,
  pdcOperatingUnit?: string | null
): IEnhancedColumn<Document>[] {
  return (
    (columnsAddList
      .map(columnKey =>
        getColumns(changedDocuments, ownerProcessId, pdcOperatingUnit).find(
          column => column.key === columnKey
        )
      )
      .filter(columnSmartFilter) as any) || []
  );
}

export function getColumnsCenterView(
  showEditDetails: boolean,
  relatedView?: boolean,
  onEditDetailsClick?: (item: DocumentInUnit) => void,
  onRowCheck?: (item: DocumentInUnit, isChecked: boolean) => void
) {
  if(relatedView)
  {
    return columnsViewList
    .map(columnKey => getColumnsCenter(onEditDetailsClick).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as IEnhancedColumn<DocumentInUnit>[];
  }
  if (showEditDetails) {
    return columnsCenterViewList
    .map(columnKey => getColumnsCenter(onEditDetailsClick).find(column => column.key === columnKey))
    .filter(columnSmartFilter) as IEnhancedColumn<DocumentInUnit>[];
}
    return columnsUnitCenterViewList
      .map(columnKey =>
        getColumnsCenter(onEditDetailsClick, onRowCheck).find(column => column.key === columnKey)
      )
      .filter(columnSmartFilter) as IEnhancedColumn<DocumentInUnit>[];
  }

  export function getColumnsAuditableView() {
      return columnsCenterViewList
      .map(columnKey => getColumnsAuditable().find(column => column.key === columnKey))
      .filter(columnSmartFilter) as IEnhancedColumn<DocumentInUnit>[];
    }

  
export function getColumnsAcknowledgement(
  changedDocuments?: DocumentSystem[],
  ownerProcessId?: number
) {
  return columnsAcknowledgement
    .map(columnKey =>
      getColumns(changedDocuments, ownerProcessId).find(column => column.key === columnKey)
    )
    .filter(columnSmartFilter) as IEnhancedColumn<DocumentSystem>[];
}
