import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {Spinner, Text, Toggle} from '@fluentui/react';
import {TreeBuilderFull} from '../../common/TreeBuilder';
import {ITreeColumn} from '../../common/TreeBuilder/TreeColumn';
import {NavLink} from 'react-router-dom';
import {MainStoreContext} from '../../../providers/MainStoreContext';
import {useService} from '../../../hooks';
import {PeoplePicker} from '../../common/PeoplePicker';
import {Helmet} from "react-helmet";
import styles from './ProcessMap.module.scss';
import {ProcessType} from '../../../enums';
import {useUnit} from '../Unit/useUnit';
import {ITreeItem} from '../../common/TreeBuilder/ITreeItem';
import {ProcessFlatStructure, ProcessTree, Unit} from '../../../models';

interface ProcessMapItem extends ITreeItem {
  processId: number;
  processOwnerAzureId: string | undefined;
  secondaryProcessOwnerAzureId: string | undefined;
  type: ProcessType;
  title: string | null;
  rawTitle: string | null;
  rawPrefix: string | null;
}
export const ProcessMap = () => {
  const {unitId, publishedProcessesForUnit, isLoading, itemsFlat, unit, unitPermissions} =
    useUnit();
  const {users} = useContext(MainStoreContext);
  const {getUserById} = useService();
  const [processMapItems, setProcessMapItems] = useState<ProcessMapItem[]>([]);
  const [unitTitle, setUnitTitle] = useState<string>();
  const [isTreePreparing, setIsTreePreparing] = useState<boolean>(false);
  const [isAnythingLoading, setIsAnythingLoading] = useState<boolean>(true);
  const [initialOpen, setInitalOpen] = useState<number[] | undefined>(undefined);
  const [counter, setCounter] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);

  const sortItemsFlatRecursiveByProcessList = useCallback(
    (process: ProcessTree, source: ProcessFlatStructure[], result: ProcessFlatStructure[]) => {
      if (process.id) {
        const index = source.findIndex(item => item.id === process.id);
        if (index >= 0) {
          result.push(source[index]);
          source.splice(index, 1);
        }
      }
      if (process.children && process.children.length)
        process.children.forEach(child => {
          sortItemsFlatRecursiveByProcessList(child, source, result);
        });
    },
    []
  );

  const toggleProcessMap = () => {
    setCounter(counter + 1);
    if (initialOpen === undefined) {
      setChecked(false)
      setInitalOpen(processMapItems.map(p => p.id));
    } else {
      setChecked(true)
      setInitalOpen(undefined);
    }
  };

  const columns = useMemo(() => {
    const cols: ITreeColumn<ProcessMapItem>[] = [
      {
        key: 1,
        title: 'Prefix',
        type: 'simple',
        onRenderColumn: model => {
          const isProcess = model.type === ProcessType.Process;
          return (
            <span
              data-attr-id={model.id}
              className={`${styles.linkTitle} ${!isProcess ? styles.nonProcess : ''} linkTitle`}>
              {model.rawPrefix}
            </span>
          );
        },
        getExportToExcelValue: model => model.rawPrefix?.toString(),
        minCSSWidth: '30px',
        maxCSSWidth: 'max-content',
        property: 'rawPrefix',
        exportToExcel: true
      },
      {
        key: 2,
        title: 'Title',
        type: 'simple',
        onRenderColumn: model => {
          const isProcess = model.type === ProcessType.Process;
          const text = (
            <Text className={`${styles.linkTitle} ${!isProcess ? styles.nonProcess : ''}`}>
              {model.rawTitle}
            </Text>
          );
          return !!isProcess ? (
            <NavLink className={styles.link} to={`/unit/${unitId}/process/${model.processId}`}>
              {text}
            </NavLink>
          ) : (
            <> {text}</>
          );
        },
        maxCSSWidth: 'max-content',
        property: 'rawTitle',
        exportToExcel: true,
        getExportToExcelValue: model =>
          model.type === ProcessType.Process
            ? {
                text: model.rawTitle,
                link: `${window.location.origin}/unit/${unitId}/process/${model.processId}`
              }
            : model.rawTitle
      },
  
      {
        key: 3,
        title: 'Process Owner',
        type: 'simple',
        property: 'processOwnerAzureId',
        maxCSSWidth: '300px',
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (model.processOwnerAzureId) {
            let user = await users.cacheById[model.processOwnerAzureId];
            if (!user) {
              user = await getUserById(model.processOwnerAzureId);
            }
            return user?.displayName;
          }
        },
        onRenderColumn: (model: ProcessMapItem) => {
          const processOwner = model.processOwnerAzureId;
          if (processOwner)
            return <PeoplePicker defaultSelectedPersonId={processOwner} isReadonly />;
          else return <></>;
        }
      },
      {
        key: 4,
        title: 'Secondary Process Owner',
        type: 'simple',
        property: 'secondaryProcessOwnerAzureId',
        maxCSSWidth: '300px',
        exportToExcel: true,
        getExportToExcelValue: async model => {
          if (model.secondaryProcessOwnerAzureId) {
            let user = await users.cacheById[model.secondaryProcessOwnerAzureId];
            if (!user) {
              user = await getUserById(model.secondaryProcessOwnerAzureId);
            }
            return user?.displayName;
          }
        },
        onRenderColumn: (model: ProcessMapItem) => {
          const secondaryProcessOwner = model.secondaryProcessOwnerAzureId;
          if (secondaryProcessOwner)
            return <PeoplePicker defaultSelectedPersonId={secondaryProcessOwner} isReadonly />;
          else return <></>;
        }
      },
    ];
    return cols;
  }, [unitId, getUserById, users.cacheById]);

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    if (unit) {
      setUnitTitle(prepareUnitTitle(unit));
    }
  }, [unit]);

  useEffect(() => {
    if (itemsFlat && itemsFlat.result) {
      if (itemsFlat.result && publishedProcessesForUnit && publishedProcessesForUnit.result) {
        setIsTreePreparing(true);
        const sourceModel = itemsFlat.result;
        const modelSorted: ProcessFlatStructure[] = [];
        publishedProcessesForUnit.result?.forEach(process => {
          sortItemsFlatRecursiveByProcessList(process, sourceModel, modelSorted);
        });

        const items: ProcessMapItem[] = modelSorted
          .filter(process => !process.deleted)
          .map(process => {
            return {
              id: process.masterProcessId,
              processId: process.id,
              parentId: process.parentMasterProcessId,
              processOwnerAzureId: process.processOwner?.azureId,
              secondaryProcessOwnerAzureId: process.secondaryProcessOwner?.azureId,
              type: process.processType,
              title: process.title,
              isChecked: true,
              rawPrefix: process.rawPrefix,
              rawTitle: process.rawTitle
            };
          });
        setProcessMapItems(items);
        setIsTreePreparing(false);
        setInitalOpen(items.map(p => p.id));
      }
    }
  }, [itemsFlat, publishedProcessesForUnit, sortItemsFlatRecursiveByProcessList]);

  useEffect(() => {
    if (!isLoading && !isTreePreparing) setIsAnythingLoading(false);
  }, [isLoading, isTreePreparing]);

  return (
    <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`} key={counter}>
      {isAnythingLoading ? (
        <Spinner />
      ) : (
        <>
          {unitTitle && (
            <Helmet>
              <title>IMS {unitTitle} Process Map</title>
            </Helmet>
          )}
          <MiddleTopBarUnit
            unitId={unitId}
            publishedData={publishedProcessesForUnit?.result}
            unitPermissions={unitPermissions}
            showManage
          />
          <HeadingWithDecoration text="Process Map" />
          <Toggle
            offText="Expanded Process Map"
            onText="Collapsed Process Map"
            inlineLabel
            defaultChecked={checked}
            onChange={() => toggleProcessMap()}
          />
          <TreeBuilderFull
            columns={columns}
            itemsFlat={processMapItems}
            isLoading={isLoading}
            initialOpen={initialOpen}
            filterDeleted={false}
            turnOffEditNonProcess={false}
            hideButtons
            exportToExcel
            onlyVisibleItemsToExcel={true}
            excelFileName="Exported Process Map"
            addGroupingBy={'rawPrefix'}
          />
        </>
      )}
    </main>
  );
};
