import {FC} from 'react';
import {FormFooter} from '../../../common/FormFooter';
import {ModalWithHeader} from '../../../common/ModalWithHeader';
import { TreeProcessModel } from '../UnitManagementProcesses/TreeProcessModel';

interface IProcssesDeleteConfirmFormProps {
  onClose: () => void;
  onConfirm: () => void;
  isConfirmModalOpen: boolean;
  processToDelete: TreeProcessModel[];
}

export const ProcessDeleteConfirmForm: FC<IProcssesDeleteConfirmFormProps> = ({
  onClose,
  onConfirm,
  isConfirmModalOpen,
  processToDelete
}) => {
  return (
    <ModalWithHeader
      dismiss={onClose}
      isVisible={isConfirmModalOpen}
      header="This will delete following processes. Are you sure?">
      <ul>
        {processToDelete.map(item => (
          <li key={item.id}>{item.processToManage.title}</li>
        ))}
      </ul>
      <FormFooter onCancel={onClose} onSubmit={onConfirm} />
    </ModalWithHeader>
  );
};
