import {useCallback, useEffect, useMemo, useState} from 'react';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Helmet} from 'react-helmet';
import {TheButton} from '../../common/TheButton';
import {
  DateColumn,
  EnhancedDetailsList,
  OtherColumn,
  ProcessesColumn,
  RichtextColumn,
  SelectColumn
} from '../../common/EnhancedDetailsList';
import {Spinner} from '@fluentui/react';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';

import {useAcknowledgementBatch} from '../../../hooks/services/useAcknowledgementBatch';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {useUnit} from '../Unit/useUnit';
import {MyAcknowledgementModel} from '../../../models/MyAcknowledgementModel';
import {UsersList} from './UsersListModal';
import {LinksListColumn} from '../../common/EnhancedDetailsList/ColumnTypes/LinksListColumn';
import {CopyColumn} from '../../common/EnhancedDetailsList/ColumnTypes/CopyColumn';
import {AcknowledgementStatus} from '../../../services/generated/models/AcknowledgementStatus';
import { UserDto } from '../../../services/generated';

export const MyAcknowledgement = () => {
  const {getMyAcknowledgement} = useAcknowledgementBatch();
  const {unitId, publishedProcessesForUnit, unit, unitPermissions} = useUnit();

  const [isAnythingLoading, setIsAnythingLoading] = useState<boolean>();
  const [myAcknowledgements, setMyAcknowledgements] = useState<MyAcknowledgementModel[]>([]);
  const [helmetTitle, setHelmetTitle] = useState<string>();
  const [selectedId, setSelectedId] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [selected, setSelected] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onOpenModal = useCallback((item: MyAcknowledgementModel) => {
    setSelectedId(item.id);
    setIsModalOpen(true);
  }, []);
  const onCloseModal = useCallback(() => {
    setSelected(false);
    setSelectedUsers([]);
    setSelectedId(0);
    setIsModalOpen(false);
  }, []);

  const AcknowledgementOptions: string[] = useMemo(
    () => ['In Progress', 'Completed', 'After Deadline', 'Expired'],
    []
  );

  const refresh = useCallback(async () => {
    setIsAnythingLoading(true);
    const response = await getMyAcknowledgement();
    if (response.result) {
      const result = response.result.map(item => new MyAcknowledgementModel(item));
      result.sort((a, b) => (a.created < b.created ? 1 : -1));
      setMyAcknowledgements(result);
    }
    setIsAnythingLoading(false);
  }, [getMyAcknowledgement]);

  const prepareHelmetTitle = useCallback(async () => {
    if (unit) {
      const unitTitle =
        unit.parentTitle && unit.unitTypeId === 2
          ? `${unit.parentTitle} ${unit.title}`
          : unit.title;
      return `IMS ${unitTitle} My Acknowledgements`;
    } else {
      return 'IMS My Acknowledgements';
    }
  }, [unit]);

  useEffect(() => {
    (async () => {
      const title = await prepareHelmetTitle();
      if (title) {
        setHelmetTitle(title);
      }
    })();
  }, [prepareHelmetTitle]);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsView = useMemo(() => {
    const columns: IEnhancedColumn<MyAcknowledgementModel>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: CopyColumn
      },
      {
        key: 'created',
        name: 'Created',
        data: DateColumn,
        minWidth: 120
      },
      {
        key: 'process',
        name: 'Process',
        data: ProcessesColumn,
        minWidth: 250
      },
      {
        key: 'comment',
        name: 'Background / Reason',
        data: RichtextColumn,
        minWidth: 360
      },
      {
        key: 'documents',
        name: 'Documents',
        minWidth: 350,
        data: LinksListColumn
      },
      {
        key: 'deadline',
        name: 'Deadline',
        data: DateColumn,
        minWidth: 120
      },
      {
        key: 'statusString',
        name: 'Status',
        data: SelectColumn,
        defaultOptions: AcknowledgementOptions,
        minWidth: 160,
        defaultFilterValue: ['In Progress', 'After Deadline']
      },
      {
        key: SpecialColumnKey.ShowUsers,
        name: '',
        minWidth: 160,
        data: OtherColumn,
        onRender: (item: MyAcknowledgementModel) => {
          return (
            <TheButton
              iconProps={{
                iconName: 'UserOptional'
              }}
              onClick={() => onOpenModal(item)}
              disabled={item.status === AcknowledgementStatus.Completed}
              primary>
              Show Users
            </TheButton>
          );
        }
      }
    ];
    return columns;
  }, [onOpenModal, AcknowledgementOptions]);

  return (
    <main className={`${isAnythingLoading ? 'isLoading' : ''} noPaddingTop`}>
      {isAnythingLoading ? (
        <Spinner />
      ) : (
        <>
          {helmetTitle && (
            <Helmet>
              <title>{helmetTitle}</title>
            </Helmet>
          )}
          <MiddleTopBarUnit
            unitId={unitId}
            publishedData={publishedProcessesForUnit?.result}
            unitPermissions={unitPermissions}
            showManage
          />
          <HeadingWithDecoration text="My Acknowledgements" />
          <UsersList
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
            selected={selected}
            setSelected={setSelected}
            acknowledgementId={selectedId}
            isModalOpen={isModalOpen}
            onCloseModal={onCloseModal}
          />
          {isAnythingLoading ? (
            <Spinner />
          ) : (
            <EnhancedDetailsList<
              MyAcknowledgementModel,
              MyAcknowledgementModel,
              MyAcknowledgementModel
            >
              columns={columnsView}
              items={myAcknowledgements}
            />
          )}
        </>
      )}
    </main>
  );
};
