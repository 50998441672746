/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 *
 *
 * 1 = InProgress
 *
 * 2 = Completed
 * 
 * 3 = AfterDeadline
 * 
 * 4 = Expired
 */

export enum AcknowledgementStatus {
  InProgress = 1,
  Completed = 2,
  AfterDeadline = 3,
  Expired = 4
}


